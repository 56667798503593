export const GET_CONTENT_CREATOR_REQUEST = "GET_CONTENT_CREATOR_REQUEST";
export const GET_CONTENT_CREATOR_SUCCESS = "GET_CONTENT_CREATOR_SUCCESS";
export const GET_CONTENT_CREATOR_FAIL = "GET_CONTENT_CREATOR_FAIL";

export const GET_CONTENT_CREATORS_REQUEST = "GET_CONTENT_CREATORS_REQUEST";
export const GET_CONTENT_CREATORS_SUCCESS = "GET_CONTENT_CREATORS_SUCCESS";
export const GET_CONTENT_CREATORS_FAIL = "GET_CONTENT_CREATORS_FAIL";

export const SUBMIT_APPLICATION_REQUEST = "SUBMIT_APPLICATION_REQUEST";
export const SUBMIT_APPLICATION_SUCCESS = "SUBMIT_APPLICATION_SUCCESS";
export const SUBMIT_APPLICATION_FAIL = "SUBMIT_APPLICATION_FAIL";
