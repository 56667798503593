export const SEND_IS_INTERESTED_REQUEST = "SEND_IS_INTERESTED_REQUEST";
export const SEND_IS_INTERESTED_SUCCESS = "SEND_IS_INTERESTED_SUCCESS";
export const SEND_IS_INTERESTED_FAIL = "SEND_IS_INTERESTED_FAIL";

export const SEND_NOT_INTERESTED_REQUEST = "SEND_NOT_INTERESTED_REQUEST";
export const SEND_NOT_INTERESTED_SUCCESS = "SEND_NOT_INTERESTED_SUCCESS";
export const SEND_NOT_INTERESTED_FAIL = "SEND_NOT_INTERESTED_FAIL";

export const GET_INFO_FOR_FORM_REQUEST = "GET_INFO_FOR_FORM_REQUEST";
export const GET_INFO_FOR_FORM_SUCCESS = "GET_INFO_FOR_FORM_SUCCESS";
export const GET_INFO_FOR_FORM_FAIL = "GET_INFO_FOR_FORM_FAIL";
