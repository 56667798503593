export const GET_REVIEW_REQUEST = "GET_REVIEW_REQUEST";
export const GET_REVIEW_SUCCESS = "GET_REVIEW_SUCCESS";
export const GET_REVIEW_FAIL = "GET_REVIEW_FAIL";

export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAIL = "GET_REVIEWS_FAIL";

export const SUBMIT_SCHEDULING_INFO_REQUEST = "SUBMIT_SCHEDULING_INFO_REQUEST";
export const SUBMIT_SCHEDULING_INFO_SUCCESS = "SUBMIT_SCHEDULING_INFO_SUCCESS";
export const SUBMIT_SCHEDULING_INFO_FAIL = "SUBMIT_SCHEDULING_INFO_FAIL";

export const GET_INFO_FOR_SCHEDULING_FORM_REQUEST = "GET_INFO_FOR_SCHEDULING_FORM_REQUEST";
export const GET_INFO_FOR_SCHEDULING_FORM_SUCCESS = "GET_INFO_FOR_SCHEDULING_FORM_SUCCESS";
export const GET_INFO_FOR_SCHEDULING_FORM_FAIL = "GET_INFO_FOR_SCHEDULING_FORM_FAIL";

