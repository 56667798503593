import React, { useState } from "react";
import './reviewImageThumbnails.css';
import {
         IonImg,
         IonThumbnail,
         IonGrid,
         IonCard,
         IonCardHeader,
         IonCardContent,
         IonCardTitle,
         IonRow,
         IonCol,
         IonLabel,
         IonModal,
         IonButton,
         IonHeader,
         IonToolbar,
         IonTitle,
         IonButtons,
         IonContent } from '@ionic/react';
import ImageSlider from "./ImageSlider";

interface Image {
  id: string,
  name: string,
  photo: string
}

interface Props {
  photos: Image[]
}

const ReviewImageThumbnails: React.FC<{featuredPhoto: Image,
   photos: Image[], thumbnailPhotos: Image[], title: string;}> = (
  { featuredPhoto, photos, thumbnailPhotos, title }) => {
  photos = photos || [];
  thumbnailPhotos = thumbnailPhotos || [];

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const handleImageClick = (e: object, imageId: number) => {
    setSelectedImage(imageId);
    setShowModal(true)
  }

  const handleFeatureImageClick = () => {
    Object.keys(photos).forEach((key: string) => {
      if (photos[key].id === featuredPhoto?.id) {
        setSelectedImage(parseInt(key));
        setShowModal(true);
      }
    })
  }

  const pics = thumbnailPhotos.map((image, i) =>
    <IonThumbnail key={image.name}
                  onClick={(e) => handleImageClick(e, i)}
                  className="reviewPhotoThumbnail">
      <IonImg key={image.id} src={image.photo} />
    </IonThumbnail>
  )
  return (
    <>
      <IonGrid>
        <IonRow>
            <IonImg onClick={handleFeatureImageClick}
                    key={featuredPhoto?.id}
                    className="reviewFeaturePhoto" src={featuredPhoto?.photo} />
        </IonRow>
        <IonRow>
          {pics}
        </IonRow>
      </IonGrid>
      <IonModal isOpen={showModal} className='imageSlider' onDidDismiss={()=>setShowModal(false)}>
        <IonHeader className="imageSliderHeader" translucent>
          <IonToolbar>
            <IonTitle>
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <ImageSlider photos={photos} selectedImage={selectedImage}/>
      </IonModal>
    </>
  );
};

export default ReviewImageThumbnails;
