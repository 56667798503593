export const ADD_ARTICLE = "ADD_ARTICLE";
export const REMOVE_ARTICLE = "REMOVE_ARTICLE";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAIL = "LOGOUT_USER_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";

// STARTING THE PROVIDER OAUTH FLOW
export const OAUTH_INITIAL_REQUEST = "OAUTH_INITIAL_REQUEST";
export const OAUTH_INITIAL_SUCCESS = "OAUTH_INITIAL_SUCCESS";
export const OAUTH_INITIAL_FAIL = "OAUTH_INITIAL_FAIL";

// SENDING AUTHORIZATION CODE TO OUR SERVER FOR A SESSION TOKEN
export const OAUTH_CONNECT_REQUEST = "OAUTH_CONNECT_REQUEST";
export const OAUTH_CONNECT_SUCCESS = "OAUTH_CONNECT_SUCCESS";
export const OAUTH_CONNECT_FAIL = "OAUTH_CONNECT_FAIL";

// INITIATING PASSWORDLESS LOGIN
export const START_PASSWORDLESS_REQUEST = "START_PASSWORDLESS_REQUEST";
export const START_PASSWORDLESS_SUCCESS = "START_PASSWORDLESS_SUCCESS";
export const START_PASSWORDLESS_FAIL = "START_PASSWORDLESS_FAIL";

// FINISH PASSWORDLESS LOGIN
export const FINISH_PASSWORDLESS_REQUEST = "FINISH_PASSWORDLESS_REQUEST";
export const FINISH_PASSWORDLESS_SUCCESS = "FINISH_PASSWORDLESS_SUCCESS";
export const FINISH_PASSWORDLESS_FAIL = "FINISH_PASSWORDLESS_FAIL";
